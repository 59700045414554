import React, {useEffect, useState} from 'react';
import { useParams } from "react-router-dom";

import HeaderConferences from '../../../components/website/HeaderConferences';
import Footer from '../../../components/website/Footer';
import Breadcrumbs from "../../../components/Breadcrumbs";
import api from "../../../api/endpoints/events";
import axios from "axios";
import {NotificationManager} from "react-notifications";

function EvaluationForm() {
    const [isLoading, setIsLoading] = useState(false);

    const { id } = useParams();
    const [conference, setConference] = useState([]);
    useEffect(() => {
        const fetchData = async () => {
            try {
                window.scrollTo(0, 0);

                const { data } = await api.get(`/Event/get-event-by-slug/${id}`);
                if(data.endDate == null) {
                    setIsValidId(false);
                    return;
                }
                setConference(data);
            } catch (error) {
            }
        };
        fetchData();
    }, [id]);

    const eventBTEL = ["5TH ANNUAL BTEL SUMMIT & EXHIBITION"];
    const evenCleaning = ["THE 6TH ANNUAL SUSTAINABLE CLEANING PRODUCTS & FORMULATION HYBRID CONFERENCE"];

// Проверяем, содержится ли имя события BTEL в массиве eventBTEL
    const isBTEL = eventBTEL.includes(conference.eventName);
// Проверяем, содержится ли имя события Cleaning в массиве evenCleaning
    const isCleaning = evenCleaning.includes(conference.eventName);

    let eventNameLabel;  // Объявляем переменную для хранения результата

    if (isBTEL) {
        eventNameLabel = "6TH ANNUAL BTEL SUMMIT & EXHIBITION";
    } else if (isCleaning) {
        eventNameLabel = "THE 7TH ANNUAL SUSTAINABLE CLEANING PRODUCTS & FORMULATION HYBRID CONFERENCE";
    } else {
        eventNameLabel = conference.eventName; // Используем название конференции, если оно не совпадает с известными
    }


    const [formData, setFormData] = useState({
        name: '',
        company: '',
        organization: '',
        roleHelpful: '',
        coveredExpected: '',
        contentMatched: '',
        discussionEval: '',
        overallEval: '',
        speakerEvals: {},
        futureEvents: '',
        conferenceType: '',
        recommendation: '',
        conferenceDuration: '',
        conferenceDurationOther: '',
        conferenceDay: '',
        speakerCount: '',
        breakDuration: '',
        breakDurationOther: '',
        citySelectedRight: '',
        preferableCity: '',
        accommodation: '',
        roomSatisfaction: '',
        conferenceRoomLiked: '',
        coffeeBreaks: '',
        cocktailReception: '',
        attendNextEvent: '',
        recommendToColleagues: '',
        recommendationComment: '',
        recommendations: '',
    });
    const [currentForm, setCurrentForm] = useState(1);
    const [isOtherSelected, setIsOtherSelected] = useState(false);
    const [citySelected, setCitySelected] = useState('Yes');
    const [hasAccommodation, setHasAccommodation] = useState(false);

    const handleAccommodationChange = (e) => {
        setHasAccommodation(e.target.value === "Yes");
    };


    const handleChange = (e) => {
        const { name, value, dataset } = e.target;
        const speakerId = dataset.speakerId; // Убедитесь, что это строка "0", если ID спикера равен 0

        console.log('Handle Change:', speakerId, name, value); // Логирование полученных значений

        if (speakerId && conference.speakers.some(speaker => speaker.id.toString() === speakerId)) {
            updateFormData(speakerId, name, value);
        } else {
            setFormData(prevState => ({
                ...prevState,
                [name]: value
            }));
            console.log('Updated formData for general field:', { ...formData, [name]: value }); // Логирование обновленного состояния для общих полей
        }
        if (name === "conferenceDuration" && value === "other") {
            setIsOtherSelected(true);
        } else if (name === "conferenceDuration" && value !== "other") {
            setIsOtherSelected(false);
        }
        if (name === "breakDuration" && value === "others") {
            setIsOtherSelected(true);
        } else if (name === "breakDuration" && value !== "others") {
            setIsOtherSelected(false);
        }
        if (name === "citySelectedRight") {
            setCitySelected(value); // Обновление состояния на основе выбора пользователя
        }
        if (name === "accommodation") {
            setHasAccommodation(value); // Задаем состояние на true, если выбрано "Yes"
        }
    };
    const formatSpeakerEvals = (speakerEvals) => {
        return Object.entries(speakerEvals).map(([speakerId, evals]) => {
            return `${Object.entries(evals).map(([key, value]) => `${key}: ${value}`).join(', ')}`;
        }).join('\n');
    };

    const formatSlackMessage = (formData) => {
        const speakerEvaluations = formatSpeakerEvals(formData.speakerEvals);
        return `
        *Event Name:* 'Evaluation Form from ${conference.eventName}'
        *Name:* ${formData.name}
        *Company:* ${formData.company}
        *Was the program well-organized?* ${formData.organization}
        *The conference will help me in my role::* ${formData.roleHelpful}
        *The conference covered what I expected it to:* ${formData.coveredExpected}
        *Session content matched description:* ${formData.contentMatched}
        *Group discussion evaluation:* ${formData.discussionEval}
         *Speaker Evaluations:*
            ${speakerEvaluations}
        *Overall session evaluation:* ${formData.overallEval}
        *Would you attend our future events?* ${formData.futureEvents}
        *What kind of conferences would you like to attend? (in-person/virtual, themes)* ${formData.conferenceType}
        *Would you recommend your colleagues participate in our events?* ${formData.recommendation}
        *How long should be a conference?:* ${formData.conferenceDuration}
        *What day is preferable for a conference?:* ${formData.conferenceDay}
        *How many speakers are you expecting at the conference?* ${formData.speakerCount}
        *How long break should be between presentations on conference?* ${formData.breakDuration}
        *Was the city for the conference selected right?:* ${formData.citySelectedRight}
        *Please, write your preferable city:* ${formData.preferableCity}
        *Did you buy a conference pass with accommodation?* ${formData.accommodation}
        *Did you like the room?:* ${formData.roomSatisfaction}
        *Did you like the coffee breaks?:* ${formData.coffeeBreaks}
        *Did you like the evening reception?:* ${formData.cocktailReception}
        *Rate the convenience of the application:* ${formData.appRating}
        *Would attend the next event?:* ${formData.attendNextEvent}
        *Would you recommend your colleagues to participate in our conference?:* ${formData.recommendationComment}
        *If you have any recommendations, please share:* ${formData.recommendations}
      
        `;
    };

    function updateFormData(speakerId, name, value) {
        setFormData(prevState => {
            const newState = {
                ...prevState,
                speakerEvals: {
                    ...prevState.speakerEvals,
                    [speakerId]: {
                        ...prevState.speakerEvals[speakerId],
                        [name]: value
                    }
                }
            };
            console.log('Updated formData for speaker:', newState); // Логирование обновленного состояния для конкретного спикера
            return newState;
        });
    }


    useEffect(() => {
        console.log("formData:", formData);
    }, [formData]);


    const nextForm = () => {
        // Проверяем, какая форма сейчас активна, и выполняем соответствующие проверки
        if (currentForm === 1) {
            // Проверяем поля на первой странице
            if (
                formData.name.trim() !== '' &&
                formData.company.trim() !== '' &&
                formData.organization &&
                formData.roleHelpful &&
                formData.coveredExpected &&
                formData.contentMatched &&
                formData.discussionEval &&
                formData.overallEval
            ) {
                setCurrentForm(currentForm + 1);
                window.scrollTo({
                    top: 0,
                    left: 0,
                    behavior: 'smooth'
                });
// Переходим на вторую страницу
            } else {
                alert('Please fill in all required fields on the current page.');
            }
        }else if (currentForm === 2) {
            setCurrentForm(currentForm + 1);
            window.scrollTo({
                top: 0,
                left: 0,
                behavior: 'smooth'
            });

        }

    };


    const postMessageToSlack = async (e, type) => {
        e.preventDefault();
        let url = '';
        if (window.location.hostname === 'localhost') {
            //url = 'https://localhost:7264/';
            url = 'https://api.bcf-events.com/';
        }
        if (window.location.hostname === 'green-desert-021865b10.3.azurestaticapps.net'
            || window.location.hostname === 'brave-dune-00ae5aa10.3.azurestaticapps.net'
            || window.location.hostname === 'new.bcf-events.com'
            || window.location.hostname === 'bcf-events.com'
        ) {
            url = 'https://api.bcf-events.com/';
        }

        try {
            const message = formatSlackMessage(formData);  // Формирование сообщения
            const data = { message: message };  // Использование ключа "message" вместо "text"

            const response = await axios.post(`${url}User/slack/${type}`, data, {
                headers: {
                    'Content-Type': 'application/json'  // Указание отправки данных в формате JSON
                }
            });

            console.log('response from endpoint: ', response);
            NotificationManager.success('Thank you for your request!\n', 'Success', 5000);
            setIsLoading(true);
            setTimeout(() => {
                window.location.reload(false);
            }, 3000);
        } catch (error) {
            console.error("Error sending message:", error);
        }
    };

    return (
        <div>
            <HeaderConferences />
            <main>
                <section>
                    <div className="background-block unsubscribe-block">
                        <div className="container">
                            <div className="breadcrumbs-about">
                                <ul>
                                    <a href="/">
                                        <li className="andThisStyle title-breadcrumbs unsubscribe-breadcrumbs">MAIN</li>
                                    </a>
                                    <li className="thisStyle description-breadcrumbs unsubscribe-breadcrumbs">
                                        EVALUATION FORM
                                    </li>
                                </ul>
                                <Breadcrumbs />
                            </div>
                            <div className="background-single"></div>
                            <img className="unsubscribe-image" style={{
                                backgroundImage: `url(${conference.eventImage})`,
                                backgroundSize: 'cover'
                            }} />
                            <div className="centered-block">
                                <h2 className="unsubscribe-title">Evaluation form</h2>
                                {currentForm === 1 && (
                                    <form className="unsubscribe-form">
                                        <label className="form-label" htmlFor="name">Your Name:</label>
                                        <input className="unsubscribe-email" type="text" id="name" name="name" value={formData.name} onChange={handleChange} required />
                                        <br />
                                        <label className="form-label" htmlFor="company">Company:</label>
                                        <input className="unsubscribe-email" type="text" id="company" name="company" value={formData.company} onChange={handleChange} required />
                                        <br />
                                        <label className="form-label">Was the program well-organized?</label>
                                        <div className="radio-container">
                                        <div className="radio-label">
                                            <input className="radio-input" type="radio" id="excellent" name="organization" value="5 - excellent" onChange={handleChange} required/>
                                            <label htmlFor="excellent">5 - Excellent</label>
                                        </div>
                                        <div className="radio-label">
                                            <input className="radio-input" type="radio" id="very-good" name="organization" value="4 - very good" onChange={handleChange} required/>
                                            <label htmlFor="very-good">4 - Very Good</label>
                                        </div>
                                        <div className="radio-label">
                                            <input className="radio-input" type="radio" id="good" name="organization" value="3 - good" onChange={handleChange} required/>
                                            <label htmlFor="good">3 - Good</label>
                                        </div>
                                        <div className="radio-label">
                                            <input className="radio-input" type="radio" id="fair" name="organization" value="2 - fair" onChange={handleChange} required/>
                                            <label htmlFor="fair">2 - Fair</label>
                                        </div>
                                        <div className="radio-label">
                                            <input className="radio-input" type="radio" id="poor" name="organization" value="1 - poor" onChange={handleChange} required/>
                                            <label htmlFor="poor">1 - Poor</label>
                                        </div>
                                        </div>
                                        <br/>

                                        <label className="form-label">The conference will help me in my role:</label>
                                        <div className="radio-container">
                                        <div className="radio-label">
                                            <input className="radio-input" type="radio" id="helpful-5" name="roleHelpful" value="5 - Very helpful" onChange={handleChange} required/>
                                            <label htmlFor="helpful-5">5 - Very helpful</label>
                                        </div>
                                        <div className="radio-label">
                                            <input className="radio-input" type="radio" id="helpful-4" name="roleHelpful" value="4 - Helpful" onChange={handleChange} required/>
                                            <label htmlFor="helpful-4">4 - Helpful</label>
                                        </div>
                                        <div className="radio-label">
                                            <input className="radio-input" type="radio" id="helpful-3" name="roleHelpful" value="3 - Neutral" onChange={handleChange} required/>
                                            <label htmlFor="helpful-3">3 - Neutral</label>
                                        </div>
                                        <div className="radio-label">
                                            <input className="radio-input" type="radio" id="helpful-2" name="roleHelpful" value="2 - Not helpful" onChange={handleChange} required/>
                                            <label htmlFor="helpful-2">2 - Not helpful</label>
                                        </div>
                                        <div className="radio-label">
                                            <input className="radio-input" type="radio" id="helpful-1" name="roleHelpful" value="1 - Not at all helpful" onChange={handleChange} required/>
                                            <label htmlFor="helpful-1">1 - Not at all helpful</label>
                                        </div>
                                        </div>
                                        <br/>
                                        <label className="form-label">The conference covered what I expected it to:</label>
                                        <div className="radio-container">
                                        <div className="radio-label">
                                            <input className="radio-input" type="radio" id="covered-5" name="coveredExpected" value="5 - Completely covered" onChange={handleChange} required/>
                                            <label htmlFor="covered-5">5 - Completely covered</label>
                                        </div>
                                        <div className="radio-label">
                                            <input className="radio-input" type="radio" id="covered-4" name="coveredExpected" value="4 - Mostly covered" onChange={handleChange} required/>
                                            <label htmlFor="covered-4">4 - Mostly covered</label>
                                        </div>
                                        <div className="radio-label">
                                            <input className="radio-input" type="radio" id="covered-3" name="coveredExpected" value="3 - Partially covered" onChange={handleChange} required/>
                                            <label htmlFor="covered-3">3 - Partially covered</label>
                                        </div>
                                        <div className="radio-label">
                                            <input className="radio-input" type="radio" id="covered-2" name="coveredExpected" value="2 - Barely covered" onChange={handleChange} required/>
                                            <label htmlFor="covered-2">2 - Barely covered</label>
                                        </div>
                                        <div className="radio-label">
                                            <input className="radio-input" type="radio" id="covered-1" name="coveredExpected" value="1 - Not covered at all" onChange={handleChange} required/>
                                            <label htmlFor="covered-1">1 - Not covered at all</label>
                                        </div>
                                        </div>
                                        <br/>
                                        <label className="form-label">Session content matched description:</label>
                                        <div className="radio-container">
                                        <div className="radio-label">
                                            <input className="radio-input" type="radio" id="content-matched-5" name="contentMatched" value="5 - Completely matched" onChange={handleChange} required/>
                                            <label htmlFor="content-matched-5">5 - Completely matched</label>
                                        </div>
                                        <div className="radio-label">
                                            <input className="radio-input" type="radio" id="content-matched-4" name="contentMatched" value="4 - Mostly matched" onChange={handleChange} required/>
                                            <label htmlFor="content-matched-4">4 - Mostly matched</label>
                                        </div>
                                        <div className="radio-label">
                                            <input className="radio-input" type="radio" id="content-matched-3" name="contentMatched" value="3 - Partially matched" onChange={handleChange} required/>
                                            <label htmlFor="content-matched-3">3 - Partially matched</label>
                                        </div>
                                        <div className="radio-label">
                                            <input className="radio-input" type="radio" id="content-matched-2" name="contentMatched" value="2 - Barely matched" onChange={handleChange} required/>
                                            <label htmlFor="content-matched-2">2 - Barely matched</label>
                                        </div>
                                        <div className="radio-label">
                                            <input className="radio-input" type="radio" id="content-matched-1" name="contentMatched" value="1 - Did not match at all" onChange={handleChange} required/>
                                            <label htmlFor="content-matched-1">1 - Did not match at all</label>
                                        </div>
                                        </div>
                                        <br/>

                                        <label className="form-label">Group discussion evaluation:</label>
                                        <div className="radio-container">
                                        <div className="radio-label">
                                            <input className="radio-input" type="radio" id="discussion-eval-5" name="discussionEval" value="5 - Excellent" onChange={handleChange} required/>
                                            <label htmlFor="discussion-eval-5">5 - Excellent</label>
                                        </div>
                                        <div className="radio-label">
                                            <input className="radio-input" type="radio" id="discussion-eval-4" name="discussionEval" value="4 - Very Good" onChange={handleChange} required/>
                                            <label htmlFor="discussion-eval-4">4 - Very Good</label>
                                        </div>
                                        <div className="radio-label">
                                            <input className="radio-input" type="radio" id="discussion-eval-3" name="discussionEval" value="3 - Good" onChange={handleChange} required/>
                                            <label htmlFor="discussion-eval-3">3 - Good</label>
                                        </div>
                                        <div className="radio-label">
                                            <input className="radio-input" type="radio" id="discussion-eval-2" name="discussionEval" value="2 - Fair" onChange={handleChange} required/>
                                            <label htmlFor="discussion-eval-2">2 - Fair</label>
                                        </div>
                                        <div className="radio-label">
                                            <input className="radio-input" type="radio" id="discussion-eval-1" name="discussionEval" value="1 - Poor"  onChange={handleChange}required/>
                                            <label htmlFor="discussion-eval-1">1 - Poor</label>
                                        </div>
                                        </div>
                                        <br/>
                                        <label className="form-label">Overall session evaluation:</label>
                                        <div className="radio-container">
                                        <div className="radio-label">
                                            <input className="radio-input" type="radio" id="overall-eval-5" name="overallEval" value="5 - Excellent" onChange={handleChange} required/>
                                            <label htmlFor="overall-eval-5">5 - Excellent</label>
                                        </div>
                                        <div className="radio-label">
                                            <input className="radio-input" type="radio" id="overall-eval-4" name="overallEval" value="4 - Very Good" onChange={handleChange} required/>
                                            <label htmlFor="overall-eval-4">4 - Very Good</label>
                                        </div>
                                        <div className="radio-label">
                                            <input className="radio-input" type="radio" id="overall-eval-3" name="overallEval" value="3 - Good" onChange={handleChange} required/>
                                            <label htmlFor="overall-eval-3">3 - Good</label>
                                        </div>
                                        <div className="radio-label">
                                            <input className="radio-input" type="radio" id="overall-eval-2" name="overallEval" value="2 - Fair" onChange={handleChange} required/>
                                            <label htmlFor="overall-eval-2">2 - Fair</label>
                                        </div>
                                        <div className="radio-label">
                                            <input className="radio-input" type="radio" id="overall-eval-1" name="overallEval" value="1 - Poor" onChange={handleChange} required/>
                                            <label htmlFor="overall-eval-1">1 - Poor</label>
                                        </div>
                                        </div>

                                        {/* Добавьте остальные поля для первой формы */}
                                        <button style={{ margin: '30px' }} className="action-button-register" type="button" onClick={nextForm}>Next</button>
                                    </form>
                                )}
                                {currentForm === 2 && (
                                    <form className="unsubscribe-form">
                                        <h3 className="form-label">Speakers</h3> {/* Заголовок списка */}
                                        {conference.speakers && (
                                            <ul className="speakers-list"> {/* Добавлен контейнер списка */}
                                                {conference.speakers.map((speaker) => (
                                                    <li className="grid-item" key={speaker.id} style={{ alignItems: 'center' }}>
                                                        <div>
                                                            <a className="popup-with-zoom-anim speaker-style" href={`#small-dialog-${speaker.id}`}>
                                                                <img src={`${speaker.profileImage}`} alt="" className="flip-img mobile-image" />
                                                            </a>
                                                        </div>
                                                        <div className="name" style={{ color: 'wheat', fontSize: '18px' }}>{speaker.fullName} | {speaker.company}</div>
                                                        <div> {/* Оценка выступления */}
                                                            {[5, 4, 3, 2, 1].map(score => (
                                                                <div className="radio-label" key={`score-${score}-${speaker.id}`}>
                                                                    <input
                                                                        className="radio-input"
                                                                        type="radio"
                                                                        id={`discussion-eval-${score}-${speaker.id}`}
                                                                        name={`${speaker.fullName}`}
                                                                        value={`${score}`}
                                                                        data-speaker-id={speaker.id.toString()}  // Убедитесь, что ID является строкой
                                                                        onChange={handleChange}
                                                                        required
                                                                    />
                                                                    <label htmlFor={`discussion-eval-${score}-${speaker.id}`}>{score} - {['Excellent', 'Very Good', 'Good', 'Fair', 'Poor'][5 - score]}</label>
                                                                </div>
                                                            ))}
                                                        </div>
                                                    </li>
                                                ))}

                                            </ul>
                                        )}
                                        <button style={{ margin: '30px' }} className="action-button-register" type="button" onClick={nextForm}>Next</button>
                                    </form>
                                )}
                                {currentForm === 3 && (
                                    <form className="unsubscribe-form" onSubmit={(e) =>
                                        postMessageToSlack(e, "bank-transfer")
                                    }>
                                        {/* Добавьте поля для третьей формы */}
                                        <label className="form-label">Group discussion evaluation:</label>
                                        <div className="radio-container">
                                            <div className="radio-label">
                                                <input className="radio-input" type="radio" id="discussion-eval-5" name="discussionEval" value="5 - Excellent" onChange={handleChange} required/>
                                                <label htmlFor="discussion-eval-5">5 - Excellent</label>
                                            </div>
                                            <div className="radio-label">
                                                <input className="radio-input" type="radio" id="discussion-eval-4" name="discussionEval" value="4 - Very Good" onChange={handleChange} required/>
                                                <label htmlFor="discussion-eval-4">4 - Very Good</label>
                                            </div>
                                            <div className="radio-label">
                                                <input className="radio-input" type="radio" id="discussion-eval-3" name="discussionEval" value="3 - Good" onChange={handleChange} required/>
                                                <label htmlFor="discussion-eval-3">3 - Good</label>
                                            </div>
                                            <div className="radio-label">
                                                <input className="radio-input" type="radio" id="discussion-eval-2" name="discussionEval" value="2 - Fair" onChange={handleChange} required/>
                                                <label htmlFor="discussion-eval-2">2 - Fair</label>
                                            </div>
                                            <div className="radio-label">
                                                <input className="radio-input" type="radio" id="discussion-eval-1" name="discussionEval" value="1 - Poor" onChange={handleChange} required/>
                                                <label htmlFor="discussion-eval-1">1 - Poor</label>
                                            </div>
                                        </div>
                                        <br/>
                                        <label className="form-label">Would you attend our future events?*</label>
                                        <div className="radio-container">
                                            <div className="radio-label">
                                                <input className="radio-input" type="radio" id="future-events-yes" name="futureEvents" value="yes" onChange={handleChange} required/>
                                                <label htmlFor="future-events-yes">Yes</label>
                                            </div>
                                            <div className="radio-label">
                                                <input className="radio-input" type="radio" id="future-events-no" name="futureEvents" value="no" onChange={handleChange} required/>
                                                <label htmlFor="future-events-no">No</label>
                                            </div>
                                        </div>
                                        <br/>
                                        <label className="form-label">What kind of conferences would you like to attend? (in-person/virtual, themes)**</label>
                                        <div className="radio-container">
                                            <div className="radio-label">
                                                <input className="radio-input" type="radio" id="conference-type-in-person" name="conferenceType" value="in-person" onChange={handleChange} required/>
                                                <label htmlFor="conference-type-in-person">In-person</label>
                                            </div>
                                            <div className="radio-label">
                                                <input className="radio-input" type="radio" id="conference-type-virtual" name="conferenceType" value="virtual" onChange={handleChange} required/>
                                                <label htmlFor="conference-type-virtual">Virtual</label>
                                            </div>
                                        </div>
                                        <br/>
                                        <label className="form-label">Would you recommend your colleagues participate in our events?*</label>
                                        <div className="radio-container">
                                            <div className="radio-label">
                                                <input className="radio-input" type="radio" id="recommendation-yes" name="recommendation" value="yes" onChange={handleChange} required/>
                                                <label htmlFor="recommendation-yes">Yes</label>
                                            </div>
                                            <div className="radio-label">
                                                <input className="radio-input" type="radio" id="recommendation-no" name="recommendation" value="no" onChange={handleChange} required/>
                                                <label htmlFor="recommendation-no">No</label>
                                            </div>
                                        </div>
                                        <br/>
                                        <label className="form-label">How long should be a conference?*</label>
                                        <div className="radio-container">
                                            <div className="radio-label">
                                                <input className="radio-input" type="radio" id="duration-3-hours" name="conferenceDuration" value="3 - Hours" onChange={handleChange} required/>
                                                <label htmlFor="duration-3-hours">3 - Hours</label>
                                            </div>
                                            <div className="radio-label">
                                                <input className="radio-input" type="radio" id="duration-5-hours" name="conferenceDuration" value="5 - Hours" onChange={handleChange} required/>
                                                <label htmlFor="duration-5-hours">5 - Hours</label>
                                            </div>
                                            <div className="radio-label">
                                                <input className="radio-input" type="radio" id="duration-6-hours" name="conferenceDuration" value="6 - Hours" onChange={handleChange} required/>
                                                <label htmlFor="duration-6-hours">6 - Hours</label>
                                            </div>
                                            <div className="radio-label">
                                                <input className="radio-input" type="radio" id="duration-other" name="conferenceDuration" value="other" onChange={handleChange} required/>
                                                <label style={{ margin: '10px' }}>Other</label>
                                                <input type="text" id="duration-other-input" name="conferenceDurationOther" disabled={!isOtherSelected} onChange={handleChange} placeholder="Other (Enter hours)" className="text-input"/>
                                            </div>
                                        </div>
                                        <br/>
                                        <label className="form-label">What day is preferable for a conference?</label>
                                        <div className="radio-container">
                                            <div className="radio-label">
                                                <input className="radio-input" type="radio" id="conference-day-monday" name="conferenceDay" value="Monday" onChange={handleChange} required/>
                                                <label htmlFor="conference-day-monday">Monday</label>
                                            </div>
                                            <div className="radio-label">
                                                <input className="radio-input" type="radio" id="conference-day-tuesday" name="conferenceDay" value="Tuesday" onChange={handleChange} required/>
                                                <label htmlFor="conference-day-tuesday">Tuesday</label>
                                            </div>
                                            <div className="radio-label">
                                                <input className="radio-input" type="radio" id="conference-day-wednesday" name="conferenceDay" value="Wednesday" onChange={handleChange} required/>
                                                <label htmlFor="conference-day-wednesday">Wednesday</label>
                                            </div>
                                            <div className="radio-label">
                                                <input className="radio-input" type="radio" id="conference-day-thursday" name="conferenceDay" value="Thursday" onChange={handleChange} required/>
                                                <label htmlFor="conference-day-thursday">Thursday</label>
                                            </div>
                                            <div className="radio-label">
                                                <input className="radio-input" type="radio" id="conference-day-friday" name="conferenceDay" value="Friday" onChange={handleChange} required/>
                                                <label htmlFor="conference-day-friday">Friday</label>
                                            </div>
                                        </div>
                                        <br/>
                                        <label className="form-label">How many speakers are you expecting at the conference?*</label>
                                        <div className="speaker-count-question">
                                            <input
                                                type="number"
                                                id="speaker-count"
                                                name="speakerCount"
                                                className="unsubscribe-email text-input"
                                                onChange={handleChange}
                                                required
                                                placeholder="Enter number of speakers"
                                            />
                                        </div>
                                        <br/>
                                        <label className="form-label">How long break should be between presentations on conference?*</label>
                                        <div className="radio-container">
                                            <div className="radio-input">
                                                <input
                                                    type="radio"
                                                    id="break-5-minutes"
                                                    name="breakDuration"
                                                    value="5 - minutes"
                                                    onChange={handleChange}
                                                    required
                                                />
                                                <label htmlFor="break-5-minutes">5 - minutes</label>
                                            </div>
                                            <div className="radio-input">
                                                <input
                                                    type="radio"
                                                    id="break-10-minutes"
                                                    name="breakDuration"
                                                    value="10 - minutes"
                                                    onChange={handleChange}
                                                    required
                                                />
                                                <label htmlFor="break-10-minutes">10 - minutes</label>
                                            </div>
                                            <div className="radio-input">
                                                <input
                                                    type="radio"
                                                    id="break-duration-others"
                                                    name="breakDuration"
                                                    value="others"
                                                    onChange={handleChange}
                                                    required
                                                />
                                                <label style={{ margin: '10px' }}>Other</label>
                                                <input
                                                    type="text"
                                                    id="break-duration-others-input"
                                                    name="breakDurationOther"
                                                    disabled={!isOtherSelected}
                                                    onChange={handleChange}
                                                    placeholder="Enter minutes"
                                                    className="text-input"
                                                />
                                            </div>
                                        </div>
                                        <br/>
                                        <label className="form-label">Was the city for the conference selected right?</label>
                                        <div className="radio-container">
                                            <div className="radio-label">
                                                <input
                                                    className="radio-input"
                                                    type="radio"
                                                    id="city-right-yes"
                                                    name="citySelectedRight"
                                                    value="Yes"
                                                    onChange={handleChange}
                                                    required
                                                />
                                                <label htmlFor="city-right-yes">Yes</label>
                                            </div>
                                            <div className="radio-label">
                                                <input
                                                    className="radio-input"
                                                    type="radio"
                                                    id="city-right-no"
                                                    name="citySelectedRight"
                                                    value="No"
                                                    onChange={handleChange}
                                                    required
                                                />
                                                <label htmlFor="city-right-no">No</label>
                                            </div>
                                        </div>
                                            {citySelected === "No" && (
                                                <div className="radio-container">
                                                    <br/>
                                                    <label className="form-label">Please, write your preferable city:</label>
                                                    <input
                                                        type="text"
                                                        name="preferableCity"
                                                        className="text-input"
                                                        style={{ width: '500px', height: '40px', textAlign: 'center'}}
                                                        onChange={handleChange} // Убедитесь, что handleChange обрабатывает это поле правильно
                                                        placeholder="Enter your preferable city"
                                                    />
                                                </div>
                                            )}
                                                <br/>
                                            <label className="form-label">Did you buy a conference pass with accommodation?</label>
                                        <div className="radio-container">
                                            <div className="radio-label">
                                                <input
                                                    className="radio-input"
                                                    type="radio"
                                                    id="accommodation-yes"
                                                    name="accommodation"
                                                    value="Yes"
                                                    onChange={handleChange}
                                                    required
                                                />
                                                <label htmlFor="accommodation-yes">Yes</label>
                                            </div>
                                            <div className="radio-label">
                                                <input
                                                    className="radio-input"
                                                    type="radio"
                                                    id="accommodation-no"
                                                    name="accommodation"
                                                    value="No"
                                                    onChange={handleChange}
                                                    required
                                                />
                                                <label htmlFor="accommodation-no">No</label>
                                            </div>
                                        </div>
                                        <br/>
                                            {hasAccommodation === "Yes" && (
                                                <div className="radio-container">
                                                    <label className="form-label" style={{ textAlign: 'center'}}>Did you like the room?</label>
                                                    <input
                                                        type="text"
                                                        name="roomSatisfaction"
                                                        className="text-input"
                                                        style={{ width: '500px', height: '40px', textAlign: 'center'}}
                                                        onChange={handleChange}
                                                        placeholder="Your feedback about the room"
                                                    />
                                                </div>
                                            )}
                                        <br/>
                                        <label className="form-label">Did you like the hotel?</label>
                                        <div className="question">
                                            <div className="radio-label">
                                                <input
                                                    className="radio-input"
                                                    type="radio"
                                                    id="hotel-yes"
                                                    name="hotel"
                                                    value="Yes"
                                                    onChange={handleChange}
                                                    required
                                                />
                                                <label htmlFor="hotel-yes">Yes</label>
                                            </div>
                                            <div className="radio-label">
                                                <input
                                                    className="radio-input"
                                                    type="radio"
                                                    id="hotel-no"
                                                    name="hotel"
                                                    value="No"
                                                    onChange={handleChange}
                                                    required
                                                />
                                                <label htmlFor="hotel-no">No</label>
                                            </div>
                                        </div>
                                        <br/>
                                        <label className="form-label">Did you like the conference room?</label>
                                        <div className="radio-container">
                                            <div className="radio-label">
                                                <input
                                                    className="radio-input"
                                                    type="radio"
                                                    id="conference-room-yes"
                                                    name="conferenceRoom"
                                                    value="Yes"
                                                    onChange={handleChange}
                                                    required
                                                />
                                                <label htmlFor="conference-room-yes">Yes</label>
                                            </div>
                                            <div className="radio-label">
                                                <input
                                                    className="radio-input"
                                                    type="radio"
                                                    id="conference-room-no"
                                                    name="conferenceRoom"
                                                    value="No"
                                                    onChange={handleChange}
                                                    required
                                                />
                                                <label htmlFor="conference-room-no">No</label>
                                            </div>
                                        </div>
                                        <br/>
                                        <label className="form-label">Did you like the coffee breaks?</label>
                                        <div className="radio-container">
                                            <div className="radio-label">
                                                <input
                                                    className="radio-input"
                                                    type="radio"
                                                    id="coffee-breaks-yes"
                                                    name="coffeeBreaks"
                                                    value="Yes"
                                                    onChange={handleChange}
                                                    required
                                                />
                                                <label htmlFor="coffee-breaks-yes">Yes</label>
                                            </div>
                                            <div className="radio-label">
                                                <input
                                                    className="radio-input"
                                                    type="radio"
                                                    id="coffee-breaks-no"
                                                    name="coffeeBreaks"
                                                    value="No"
                                                    onChange={handleChange}
                                                    required
                                                />
                                                <label htmlFor="coffee-breaks-no">No</label>
                                            </div>
                                        </div>
                                        <label className="form-label">Did you like the evening reception?</label>
                                        <div className="radio-container">
                                            <div className="radio-label">
                                                <input
                                                    className="radio-input"
                                                    type="radio"
                                                    id="cocktail-reception-yes"
                                                    name="cocktailReception"
                                                    value="Yes"
                                                    onChange={handleChange}
                                                    required
                                                />
                                                <label htmlFor="cocktail-reception-yes">Yes</label>
                                            </div>
                                            <div className="radio-label">
                                                <input
                                                    className="radio-input"
                                                    type="radio"
                                                    id="cocktail-reception-no"
                                                    name="cocktailReception"
                                                    value="No"
                                                    onChange={handleChange}
                                                    required
                                                />
                                                <label htmlFor="cocktail-reception-no">No</label>
                                            </div>
                                        </div>
                                        <br/>
                                        <label className="form-label">
                                            Would you like to attend the next {eventNameLabel}?
                                        </label>
                                        <div className="radio-container">
                                            <div className="radio-label">
                                                <input
                                                    className="radio-input"
                                                    type="radio"
                                                    id="attend-next-event-yes"  // Изменено для соответствия контексту
                                                    name="attendNextEvent"  // Изменено для соответствия вашей переменной в formData
                                                    value="Yes"
                                                    onChange={handleChange}
                                                    required
                                                />
                                                <label htmlFor="attend-next-event-yes">Yes</label>
                                            </div>
                                            <div className="radio-label">
                                                <input
                                                    className="radio-input"
                                                    type="radio"
                                                    id="attend-next-event-no"  // Изменено для соответствия контексту
                                                    name="attendNextEvent"  // Изменено для соответствия вашей переменной в formData
                                                    value="No"
                                                    onChange={handleChange}
                                                    required
                                                />
                                                <label htmlFor="attend-next-event-no">No</label>
                                            </div>
                                        </div>
                                        <br/>
                                        <label className="form-label">Would you recommend your colleagues to participate in our conference?</label>
                                        <div className="question">
                                            <textarea
                                                className="unsubscribe-email text-input"
                                                id="recommendation-comment"
                                                name="recommendationComment"
                                                onChange={handleChange}
                                                placeholder="Your comments"
                                                rows="1"  // Можно настроить количество строк в соответствии с предпочтениями
                                                required
                                            />
                                        </div>
                                        <br/>
                                        <label className="form-label" style={{ margin: '10px' }}>If you have any recommendations, please share:</label>
                                        <div className="question">
                                            <textarea
                                                className="unsubscribe-email text-input"
                                                id="recommendations"
                                                name="recommendations"
                                                onChange={handleChange}
                                                placeholder="Type your recommendations here"
                                                rows="4" // Вы можете настроить количество строк в зависимости от ожидаемой длины ответа
                                                required
                                            />
                                        </div>
                                        <button className="canister-button" type="submit">Submit</button>
                                    </form>
                                )}
                            </div>
                        </div>
                    </div>
                </section>
            </main>
            <Footer />
        </div>
    );
}

export default EvaluationForm;
