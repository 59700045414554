import React, { useEffect } from "react";
import Footer from "../../components/website/Footer";
import HeaderConferences from "../../components/website/HeaderConferences";
import Breadcrumbs from "../../components/Breadcrumbs";

function Privacy() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
      <HeaderConferences />
      <main>
        <section>
          <div className="container">
            <div className="breadcrumbs-about breadcrumbs-add">
              <ul>
                <li className="andThisStyle title-breadcrumbs">MAIN</li>
                <li className="thisStyle description-breadcrumbs">
                  DATA POLICY
                </li>
              </ul>
              <Breadcrumbs />
            </div>
            <h2 className="title-conferences title-contact-us event-contact-us">
              Data policy
            </h2>
            <div className="line-conferences line-contact-us line-event-us">
              <span className="line-about"></span>
            </div>
          </div>
        </section>
        <section>
          <div className="container">
            <h3 className="title-terms">Introduction</h3>
            <p className="text-terms">
              This policy sets out how we collect, process and hold your data if
              you visit our website or otherwise provide personal data to us. We
              are bcf-events.com of Business Conference Facilitation. This
              policy affects your legal rights and obligations, so please read
              it carefully. If you have any questions about Information we
              collect, store and process, please contact us at
              general@bcf-events.com
            </p>
            <h3 className="title-terms">General Information</h3>
            <p className="text-terms">
              When you visit, interact or use our website, we collect general
              data and may as well collect personal data. General Data usually
              includes cookies that is recorded automatically when you first
              visit the website, to give you a better experience when you
              revisit our website.
            </p>
            <p className="text-terms">
              Personal Data include your name, your contact information and few
              other personal details as permitted by you. We collect, process,
              store and use personal data when you authorize us to see your
              personal details after you log in on our platform directly or
              through third party login system. We store your personal
              information for a limited time period when you submit registration
              to an event.
            </p>
            <p className="text-terms">
              You do not need to provide us with any personal information to
              view our website. However, we may still collect the information
              set under the cookie statement section of this policy, ‘Data we
              automatically collect’ section of this policy and marketing
              communications in accordance with the ‘Marketing Communications’
              section of this policy. When you contact us by email or post, we
              may keep a record of the correspondence, and we may also record
              any telephone call we have with you.
            </p>
            <h3 className="title-terms">Users</h3>
            <p className="text-terms">
              General information is automatically recorded from all the users.
              The data which is automatically collected includes the technical
              data that is sent to us from the computer, mobile device and
              browser through which users access our website or it’s services.
            </p>
            <p className="text-terms">
              Users action on our website, information on how users came to our
              website and data collected through Cookies, Pixel Code and other
              similar technologies are all included in the automatic general
              information collection.
            </p>
            <h3 className="title-terms">
              Consumers/Event Attendees/Subscribers
            </h3>
            <p className="text-terms">
              If you are a consumer or an event attendee or someone who uses
              bcf-events.com, we collect additional data from your end such as
              your name and contact information. This additional data is mostly
              used for our own purposes. However, when you register for an event
              through bcf-events.com, we collect that data, and we will only use
              the data to invite you to our future events.
            </p>
            <p className="text-terms">
              If you other emails apart from invitation to any of our future
              events. You can at any point unsubscribe directly from such mails
              or contact us directly to delete your data. If you contact us, we
              may remove or update your information within a reasonable time.
            </p>
            <h3 className="title-terms">Data we automatically collect</h3>
            <p className="text-terms">
              When you visit our website, login or register from our website,
              we, or third parties on our behalf, automatically collect and
              store information about your device and your activities. This
              information could include (a) your computer or other device’s
              unique ID number; (b) technical information about your device such
              as type of device, web browser or operating system; (c) your
              preferences and settings such as time zone and language, and (d)
              statistical data about your browsing actions and patterns. We
              collect this information using cookies in accordance with the
              Cookie section of this policy, and we use the information we
              collect on an anonymous basis to improve our events and events
              related services we provide, and for analytical and research
              purposes.
            </p>
            <h3 className="title-terms">
              Event Updates and Other Electronic Communication
            </h3>
            <p className="text-terms">
              It is necessary for our legitimate interests to use your contact
              information and interests to send you updates and other
              communications, which may include newsletters, blog posts,
              surveys, case studies, our products or services, offers, general
              queries, feedback and information about new events. You can choose
              to no longer receive particular or any type of communication by
              contacting us at general@bcf-events.com or clicking “Unsubscribe"
              from an email.
            </p>
            <p className="text-terms">
              If you do unsubscribe to any particular communication, it may take
              up to 5 business days for your new preferences to take effect. We
              shall therefore retain your personal data in our records for
              business purposes until you notify us that you no longer wish to
              receive emails from us. Please note that unsubscribing from a
              particular communication email does not mean unsubscribing from
              all the emails. You can choose which emails you would like to
              receive by reviewing your settings.
            </p>
            <p className="text-terms">
              To do so, please use the update your preference link which will be
              at any of the emails you received from а Business Conference
              Facilitation. At any point you can change settings for the type of
              communication you want or do not want to receive. Unsubscribing
              from marketing or service related communication emails does not
              mean that you may not receive weekly newsletter or event updates
              for which you opted in or based on the organizer profiles or city
              you follow or showed your interest at the time of signing up on
              bcf-events.com. If you never want to receive any emails from us or
              want to delete your preferences & profile, you can raise your
              concern on general@bcf-events.com.
            </p>
            <h3 className="title-terms">Organizer Initiated Communications</h3>
            <p className="text-terms">
              We use email tools by 3rd party providers through which we can
              send electronic communication to those who are on our email
              subscription lists, including consumers who may have registered
              for their upcoming and past events. We send event invitations to
              our customers. The recipients can choose to “opt out" of
              electronic communications from a particular event through the
              “Unsubscribe" link at the end of each of these emails.
            </p>
            <h3 className="title-terms">
              Lawful processing of your personal data
            </h3>
            <p className="text-terms">
              We will use your data in order to comply with our contractual
              obligation to supply to you the tickets to an event that you have
              booked, including to contact you with any information relating to
              the event, to deliver the event to you in accordance with any
              requests you make and that we agree to, and to deal with any
              questions, comments or complaints you have in relation to the
              event. We may also use your personal data for our legitimate
              interests, including dealing with any customer services you
              require, enforcing the terms of any other agreement between us,
              for regulatory and legal purposes (for example anti-money
              laundering), for audit purposes and to contact you about changes
              to this policy.
            </p>
            <h3 className="title-terms">Who do we share your data with?</h3>
            <p className="text-terms">
              We may share your data with any service providers, subcontractors
              and agents that we may appoint to perform functions on our behalf
              and in accordance with our instructions, including payment
              providers, email communication providers, IT service providers,
              partner platforms, accountants, auditors and lawyers. Under
              certain circumstances we may have to disclose your personal data
              under applicable laws and/or regulations, for example, as part of
              anti-money laundering processes or protect a third party’s rights,
              property, or safety. We may also share your personal data in
              connection with, or during negotiations of, any merger, sale of
              assets, consolidation or restructuring, financing, or acquisition
              of all or a portion of our business by or into another company.
            </p>
            <h3 className="title-terms">
              Where we hold and process your personal data
            </h3>
            <p className="text-terms">
              Some or all of your personal data may be stored or transferred
              outside of the European Union (the EU) for any reason, including
              for example, if our email server is located in a country outside
              the EU or if any of our service providers or their servers are
              based outside of the EU. We shall only transfer your personal data
              to organizations that have provided adequate safeguards in respect
              of your personal data and are GDPR Compliant.
            </p>
            <h3 className="title-terms">Retention</h3>
            <p className="text-terms">
              If you receive communication from us, we shall retain your data
              until you opt out of receiving such communications. If you
              register with us, we shall retain your data until you close your
              account. You can delete your data or your account by contacting us
              on general@bcf-events.com However, we may hold your data for an
              extra period as is allowed or required under applicable laws. Your
              data after complete deletion might still be available on backup
              for even a longer period of time for reasons persistence to legal,
              tax and lawful business purposes. If you have otherwise booked a
              ticket with us or contacted us with a question or comment, we
              shall retain your personal information for at least 6 months
              following such contact to respond to any further queries you might
              have. When an individual books a ticket for an event, their
              personal data like name, email address and other registration
              information for coordinating check-ins during the event. If by any
              means.
            </p>
            <h3 className="title-terms">Cookies</h3>
            <p className="text-terms">
              Cookie is a small text file containing a unique identification
              number that is transferred (through your browser) from a website
              to the hard drive of your computer. The cookie identifies your
              browser but will not let a website know any personal data about
              you, such as your name and/or address. These files are then used
              by websites to identify when users revisit that website. Our
              website uses cookies so that we can recognize you when you return
              and personalize your settings and preferences. Most browsers are
              initially set up to accept cookies. You can change your browser
              settings either to notify you when you have received a cookie, or
              to refuse to accept cookies.
            </p>
            <p className="text-terms">
              Please note that we may not be able to remember your choices or
              operate efficiently or give you personalised experience if you
              refuse to accept cookies. We also use Google Analytics to monitor
              how our website is used. Google Analytics collects information
              anonymously and generates reports detailing information such as
              the number of visits to the event pages, where visitors generally
              came from, how long they stayed on the event pages, and which
              event pages they visited. Google Analytics places several
              persistent cookies on your computer’s hard drive. These do not
              collect any personal data. If you do not agree to this you can
              disable persistent cookies in your browser.
            </p>
            <p className="text-terms">
              This will prevent Google Analytics from logging your visits. We
              also use Social Networking Cookies, analytics or tracking pixels
              on our website, which remembers your interest for a particular
              event or piece of information available on our and partner
              websites. This is done to show you relevant ads on the social
              networking sites. For more information about the pixel tracking
              you can check out Facebook Data Policy and Smart look. To support
              our business we also allow third party advertisements on our
              website. Some of these advertisers may use technology such as
              cookies and web beacons when they advertise on our website, which
              will also send these advertisers your automatically collected
              information such as IP address, your ISP, the browser you used to
              visit our site when you interact or even see their advertisement
              on our site. For more details regarding how we third party
              vendors, including google manages data in its ads products, please
              check the partner-sites policy as provided by Google.
            </p>
            <h3 className="title-terms">Security</h3>
            <p className="text-terms">
              We shall process your data in a manner that ensures appropriate
              security of the data, including protection against unauthorized or
              unlawful processing and against accidental loss, destruction or
              damage, using appropriate technical or organizational measures.
              All information you provide to us is stored on our secure servers.
              Any payment transactions are encrypted using SSL technology. Where
              we have given, or you have chosen a password, you are responsible
              for keeping this password confidential. However, you acknowledge
              that no system can be completely secure. Therefore, although we
              take these steps to secure your data, we do not promise that your
              data will always remain completely secure.
            </p>
            <h3 className="title-terms">Your rights</h3>
            <p className="text-terms">
              You have the right to obtain from us a copy of the personal data
              that we hold for you, and to require us to correct errors in that
              data if it is inaccurate or incomplete. You also have the right at
              any time to require that we delete your personal data, object to
              profiling and unsubscribe from marketing communications. To
              exercise these rights, or any other rights you may have under
              applicable laws, please contact us at general@bcf-events.com.
              Please note, we reserve the right to charge an administrative fee
              if your request is manifestly unfounded or excessive. If you have
              any complaints in relation to this policy or otherwise in relation
              to our processing of your data, please get in touch with us at
              general@bcf-events.com, so we can resolve your complaint. If you
              are not satisfied with our attempt to resolve your privacy related
              complaints, you may contact the relevant data protection
              authority.
            </p>
            <p className="text-terms">
              Our website pages may contain links to other sites of interest.
              Once you have used these links to leave our platform, you should
              note that we do not have any control over that other site.
              Therefore, we cannot be responsible for the protection and privacy
              of any information which you provide whilst visiting such sites
              and such sites are not governed by this policy. You should
              exercise caution and look at the privacy policy applicable to the
              site in question.
            </p>
            <h3 className="title-terms">API & Plugins Use</h3>
            <p className="text-terms">
              Bcf-events.com aggregates public events from multiple third party
              providers from their APIs. We take request from APIs only for the
              public events and not private events. In cases, where a user or an
              event organizer or any individual might want to report an event,
              they can do so through the event page or by contacting us at
              general@bcf-events.com We also provide APIs and Event Plugins to
              third party or partner websites which implies that when an
              individual creates an event on bcf-events.com, it may be used by
              third party or partner websites to display your events for a
              greater reach.
            </p>
            <h3 className="title-terms">
              Facebook, Google and Other Third Party Integrations
            </h3>
            <p className="text-terms">
              All the third party integrations and the company or organization
              names mentioned in this policy have their own copyright/word mark,
              trademark, terms, security and privacy policy which can be found
              on their own website.
            </p>
            <h3 className="title-terms">Changes of this privacy statement</h3>
            <p className="text-terms last-terms">
              We may change the terms of this policy from time to time. You are
              responsible for regularly reviewing this policy so that you are
              aware of any changes to it. If you continue to use our website
              after the time we state the changes will take effect, you will
              have accepted the changes.
            </p>
          </div>
        </section>
      </main>
      <Footer />
    </div>
  );
}

export default Privacy;
